import request from '../utils/requrst'
// POST


export function getFoodList(params) {
  return request({
    url: '/api/food/list',
    method: 'GET',
    params
  })
}
