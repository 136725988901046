<template>
  <div class="bigDiv">
    <div class="topSarch">
      <van-field v-model="text" placeholder="请输入食物名称可查询营养和热量" border @focus='inputfocus' ref="search">
        <template #left-icon>
          <div class="leftIcon">
            <img src="../../assets/images/foodList/search.png" alt="">
          </div>
        </template>

        <template #button>
          <div class="serch" @click="search">
            搜索</div>
        </template>
      </van-field>
    </div>
    <!-- s搜索记录 -->
    <div v-if="searchJiLu">
      <div class="searchTitle">
        <h3>搜索记录</h3>
        <div class="delImg" @click="delLIst"><img src="../../assets/images/foodList/dea.png" alt=""></div>
      </div>
      <div class="tag">
        <span class="tags" v-for="item in listKeys " :key="item" @click="listLishi(item)">
          {{item}}
        </span>
      </div>
    </div>
    <div v-else>
      <div v-if="list.length">
        <div v-for="item in list" :key="item.id" @click="goodsInfo(item)">
          <div class="info">
            <div class="goodsImg">
              <img :src="item.img_url" alt="" v-if="item.img_url!==''">
              <img src="../../assets/images/foodList/noImg.jpg" alt="" v-else>
            </div>
            <div class="goodsInfo">
              <div class="goodsTit">{{item.name}}</div>
              <div class="goodsNengliang"><span v-for=" res in item.info" :key="res.key" v-show="res.key==='热量(千卡)'">{{res.value}}</span>/100克</div>
            </div>
          </div>
          <div class="xian"></div>

        </div>
      </div>
      <div v-else class="zanwu"> 暂无数据~</div>
    </div>
  </div>
</template>

<script>
import { getFoodList } from "@/api/foodApi.js";
import { getAccountOauthCodes } from "@/api/components";
import { getWechatConfig } from "@/api/yangsheng";

import { Toast } from "vant";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      listKeys: [],
      searchJiLu: true,
      text: '',
      list: []
    }
  },
  created () {
    const token = localStorage.getItem('token')
    if (!token) {
      //  this.getAccountOauthCodes()
    }

    const searchText = this.$route.query.searchText
    if (searchText && searchText.length > 0) {
      this.text = searchText
      this.listLishi(searchText)
    }
    if (JSON.parse(sessionStorage.getItem('listKeys'))) {
      this.listKeys = JSON.parse(sessionStorage.getItem('listKeys'))

    }
  },
  methods: {
    // 分享
    getWechatConfig () {

      const query = {
        company_id: 1,
        url: window.location.href
      }
      getWechatConfig(query).then(res => {
        const agent = res.data.data
        if (res.data.code === 200) {
          this.$nextTick(() => {

          })
          wx.config({
            debug: agent.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: agent.appId, // 必填，公众号的唯一标识
            timestamp: agent.timestamp, // 必填，生成签名的时间戳
            nonceStr: agent.nonceStr, // 必填，生成签名的随机串
            signature: agent.signature,// 必填，签名
            //1.4.0的分享接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
            //     "onMenuShareAppMessage", //老版本分享接口。
            //     "onMenuShareTimeline" //老版本分享接口。
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData,'onMenuShareAppMessage", "onMenuShareTimeline"] // 必填，需要使用的JS接口列表
          });

          this.share()
        }

      }
      )
    },
    share () {
      this.$nextTick(() => {
        wx.ready(function () {
          // 分享给朋友
          wx.updateAppMessageShareData({
            title: '食物搜索', // 分享标题
            link: 'https://kf.hshwhkj.com/foodList', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '营养食疗专业查询工具', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          });
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: '食物搜索', // 分享标题
            link: 'https://kf.hshwhkj.com/foodList', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '营养食疗专业查询工具', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          })

        });
        wx.error(res => {
          console.log('微信分享错误信息：', res)
        })
      })



    },

    async getAccountOauthCodes () {
      try {
        const data = {
          company_id: 1,
          code: this.$route.query.code
        };
        const res = await getAccountOauthCodes(data)
        localStorage.setItem('token', res.data.data.token)
      } catch (error) {
        Toast.fail('缺少必要信息,请重新获取!')
        console.log(error);
        localStorage.removeItem('token')
        this.$router.replace({
          path: '/foodList',

        })
      }
    },
    delLIst () {
      console.log(1);
      this.listKeys = []
      sessionStorage.setItem('listKeys', JSON.stringify(this.listKeys))
    },
    async listLishi (item) {
      this.text = item
      const { data } = await getFoodList({ keywords: this.text })
      console.log(data.data);
      this.list = data.data
      this.searchJiLu = false
    },
    async search () {
      console.log(this.listKeys);
      if (this.text !== '') {
        this.listKeys.push(this.text)
        const uniqueArray = this.listKeys.filter((value, index, self) => {
          if (typeof value === 'number' || typeof value === 'string') {
            return self.indexOf(value) === index;
          } else {
            return true; // 如果是其他类型（比如汉字），则直接保留
          }
        });
        console.log(uniqueArray);
        this.listKeys = uniqueArray
        sessionStorage.setItem('listKeys', JSON.stringify(this.listKeys))
      }
      const { data } = await getFoodList({ keywords: this.text })
      console.log(data.data);
      this.list = data.data
      this.searchJiLu = false
    },
    inputfocus () {
      console.log(1);
      this.searchJiLu = true
    },
    goodsInfo (item) {

      console.log(item);
      sessionStorage.removeItem('foodInfo')
      sessionStorage.setItem('foodInfo', JSON.stringify(item))
      this.$router.push({
        path: '/foodInfo'
      })
    }
  },
  mounted () {
    this.$refs.search.focus()
    this.getWechatConfig()
  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>

<style scoped lang='less'>
.bigDiv {
  overflow: hidden;
}
.topSarch {
  width: 340px;
  height: 50px;
  margin: 0 auto;
  position: relative;
  .serch {
    color: #00a37c;
    width: 60px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .leftIcon {
    width: 40px;
    text-align: center;
    img {
      width: 20px;
      object-fit: contain;
      display: inline-block;
      margin-top: 10px;
    }
  }
}
.searchTitle {
  margin: 20px 15px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  height: 20px;
  text-align: center;
  align-items: center;
  .delImg {
    width: 20px;
    img {
      width: 100%;
      object-fit: contain;
      display: inline-block;
      margin-top: 8px;
    }
  }
}
.tag {
  font-size: 12px;
  width: 360px;
  margin: 10px auto;
  .tags {
    min-width: 15px;
    text-align: center;
    height: 15px;
    line-height: 15px;
    background-color: #f8f8f8;
    color: #333333;
    display: inline-block;
    padding: 5px;
    margin: 5px;
    border-radius: 50px;
  }
}
.info {
  display: flex;
  width: 340px;
  margin: 10px auto;
  .goodsImg {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .goodsInfo {
    margin-left: 10px;
    margin-top: 10px;
    .goodsTit {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .goodsNengliang {
      font-size: 16px;
      color: #999999;
      span {
        color: #ff7b35;
      }
    }
  }
}
.xian {
  width: 340px;
  height: 0.8px;
  margin: 20px auto;
  background-color: #f2f2f2;
}
.zanwu {
  width: 350px;
  margin: 0 auto;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    overflow: hidden;
    margin: 0 auto;
    .topSarch {
      width: 750px;
      height: 50px;
      margin: 0 auto;
      position: relative;
      .serch {
        color: #00a37c;
        width: 60px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        font-size: 14px;
        position: absolute;
        top: 0;
        right: 0;
      }
      .leftIcon {
        width: 40px;
        text-align: center;
        img {
          width: 20px;
          object-fit: contain;
          display: inline-block;
          margin-top: 10px;
        }
      }
    }
    .searchTitle {
      margin: 20px 15px;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 700;
      height: 20px;
      text-align: center;
      align-items: center;
      .delImg {
        width: 20px;
        img {
          width: 100%;
          object-fit: contain;
          display: inline-block;
          margin-top: 8px;
        }
      }
    }
    .tag {
      font-size: 12px;
      width: 750px;
      margin: 10px auto;
      .tags {
        min-width: 15px;
        text-align: center;
        height: 15px;
        line-height: 15px;
        background-color: #f8f8f8;
        color: #333333;
        display: inline-block;
        padding: 5px;
        margin: 5px;
        border-radius: 50px;
      }
    }
    .info {
      display: flex;
      width: 750px;
      margin: 10px auto;
      .goodsImg {
        width: 70px;
        height: 70px;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .goodsInfo {
        margin-left: 10px;
        margin-top: 10px;
        .goodsTit {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 10px;
        }
        .goodsNengliang {
          font-size: 16px;
          color: #999999;
          span {
            color: #ff7b35;
          }
        }
      }
    }
    .xian {
      width: 740px;
      height: 0.8px;
      margin: 20px auto;
      background-color: #f2f2f2;
    }
  }
}
</style>
<style>
.van-field {
  margin: 10px auto;
  border-radius: 50px;
  background-color: #f8f8f8;
}
.van-field__control {
  margin-top: 7px;
}
.van-cell {
  padding: 0px;
}
@media screen and (min-width: 750px) {
  .van-field__control {
    margin-top: 8px;
  }
}
</style>